//Go to scroll
function goToElement (element) {
	$('html, body').animate({ scrollTop: $(element).offset().top}, 600);
}
//Go to scroll

//Detect devide
const ua = navigator.userAgent;
const os = /Android/.test(ua)
	? 'android'
	: /iPhone/.test(ua)
		? 'ios'
		: /iPod/.test(ua)
			? 'ios'
			: /iPad/.test(ua)
				? 'ios'
				: 'default';
const bodyelement = document.getElementsByTagName('BODY')[0];
switch (os) {
	case 'android':
		bodyelement.classList.add('android');
		break;
	case 'ios':
		bodyelement.classList.add('ios');
		break;
	default:
		bodyelement.classList.add('default');
}
//Detect devide

// Truncate text (using line-cutter.js lib)
$(function() {
  let articlesIngress = $('.articles__listArticles--item .infoItem .ingressItem p');
  let relatedContentIngress = $('.related-content__item .infoItem .ingressItem p');

  $(window).trigger('resize');
  $(window).resize(function () {
    articlesIngress.line(3,' ');
    relatedContentIngress.line(3,' ');
  });
  setTimeout(function(){ $(window).trigger('resize');}, 100);
});
// End truncate text (using line-cutter.js lib)

$(document).ready(function () {

	// Filter
	let toggleFilter = $(".filterBtn .btn, .projects__filter--options .optionsHeader__btnClose, .overlayOptions"),
	filterOptions = $(".projects__filter--options, .overlayOptions");

	toggleFilter.click(function(){
	filterOptions.toggleClass("active");
	});



});
